import dayjs from '../../config/dayjs';
import REVOKE_REFRESH_TOKEN from '../../graphql/auth/mutations';
import { refreshTokenVar, tokenVar } from '../reactive-variables/auth';
import { customMutation } from '../../helpers/custom-mutation';
import { setLocalStorageVariable } from './local-storage';
import { cleanAllShopping } from './shopping-cart';

const verifyToken = ({ payload }) => {
  if (payload) {
    const delayOfExpire = 60;
    const difference = payload.exp - payload.origIat;
    const expires = dayjs().unix() + difference - delayOfExpire;
    setLocalStorageVariable('tokenExpire', expires);
  }
};

export const logIn = ({ token, refreshToken, payload }) => {
  cleanAllShopping();
  setLocalStorageVariable('token', token);
  setLocalStorageVariable('refreshToken', refreshToken);
  tokenVar(token);
  refreshTokenVar(refreshToken);
  verifyToken({ payload });
};

const CACHE_DATA = ['ROOT_QUERY', 'CountryType:1', 'CountryType:2'];

export const logOut = (client) => {
  cleanAllShopping();
  const refreshToken = refreshTokenVar();
  if (refreshToken) {
    customMutation(REVOKE_REFRESH_TOKEN, {
      refreshToken,
    });
  }
  setLocalStorageVariable('token', undefined);
  setLocalStorageVariable('refreshToken', undefined);
  setLocalStorageVariable('tokenExpire', undefined);
  tokenVar(undefined);
  refreshTokenVar(undefined);
  if (client) {
    localStorage.removeItem('apollo-cache-persist');
    localStorage.removeItem('apolloVariables');
    const clientCacheData = client.cache.data;
    Object.keys(clientCacheData.data).forEach(
      (key) => !CACHE_DATA.includes(key) && clientCacheData.delete(key),
    );
  }
};
