import {
  offerDateToPaySelected,
  offerSelected,
  preofferSelected,
} from '../reactive-variables/invoices';
import {
  orderingInvoiceIssuedSelected,
  orderingOfferSelected,
  orderingSelected,
} from '../reactive-variables/purchase-orders';

export const cleanOfferShoppingCart = () => {
  offerSelected([]);
  offerDateToPaySelected({});
};

export const cleanPreOfferShoppingCart = () => {
  preofferSelected([]);
};

export const cleanOrderingShoppingCart = () => {
  orderingSelected([]);
  orderingInvoiceIssuedSelected({});
};

export const cleanOrderingOfferShoppingCart = () => {
  orderingOfferSelected([]);
};

export const cleanAllShopping = () => {
  cleanOfferShoppingCart();
  cleanOrderingShoppingCart();
  cleanPreOfferShoppingCart();
  cleanOrderingOfferShoppingCart();
};
