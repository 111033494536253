import React from 'react';
import Icon from '@mui/material/Icon';
import PencilIconImage from './pencil.svg';

const PencilIcon = (props) => (
  <Icon {...props}>
    <img src={PencilIconImage} alt="pencil-icon" />
  </Icon>
);

export { ReactComponent as ReviewDocumentIcon } from './review_invoices_on_time.svg';
export { ReactComponent as VisualizeAccountIcon } from './visualize_accounts.svg';
export { ReactComponent as FinanceIcon } from './check_key_financial_metrics.svg';
export { ReactComponent as RateGraphIcon } from './best_interest_rates_financing.svg';
export { ReactComponent as SandClock } from './sand_clock.svg';
export { PencilIcon };
